export const FETCH_DASHBOARD_NEWS = '@@dashboard/FETCH_DASHBOARD_NEWS';
export const FETCH_DASHBOARD_NEWS_SUCCESS = '@@dashboard/FETCH_DASHBOARD_NEWS_SUCCESS';
export const FETCH_DASHBOARD_NEWS_ERROR = '@@dashboard/FETCH_DASHBOARD_NEWS_ERROR';

export const CHECK_DASHBOARD_NEWS_COUNT = '@@dashboard/CHECK_DASHBOARD_NEWS_COUNT';
export const CHECK_DASHBOARD_NEWS_COUNT_SUCCESS = '@@dashboard/CHECK_DASHBOARD_NEWS_COUNT_SUCCESS';
export const CHECK_DASHBOARD_NEWS_COUNT_ERROR = '@@dashboard/CHECK_DASHBOARD_NEWS_COUNT_ERROR';

export const FETCH_DASHBOARD_CATEGORY_EQUIPMENT = '@@dashboard/FETCH_DASHBOARD_CATEGORY_EQUIPMENT';
export const FETCH_DASHBOARD_CATEGORY_EQUIPMENT_SUCCESS = '@@dashboard/FETCH_DASHBOARD_CATEGORY_EQUIPMENT_SUCCESS';
export const FETCH_DASHBOARD_CATEGORY_EQUIPMENT_ERROR = '@@dashboard/FETCH_DASHBOARD_CATEGORY_EQUIPMENT_ERROR';
export const CANCEL_FETCH_DASHBOARD_CATEGORY_EQUIPMENT = '@@dashboard/CANCEL_FETCH_DASHBOARD_CATEGORY_EQUIPMENT';

export const fetchDashboardNews = () => ({
  type: FETCH_DASHBOARD_NEWS
});

export const fetchDashboardNewsSuccess = resp => ({
  type: FETCH_DASHBOARD_NEWS_SUCCESS,
  payload: resp
});

export const fetchDashboardNewsError = err => ({
  type: FETCH_DASHBOARD_NEWS_ERROR,
  payload: err
});

export const checkDashboardNewsCount = () => ({
  type: CHECK_DASHBOARD_NEWS_COUNT
});

export const checkDashboardNewsCountSuccess = count => ({
  type: CHECK_DASHBOARD_NEWS_COUNT_SUCCESS,
  count,
});

export const checkDashboardNewsCountError = err => ({
  type: CHECK_DASHBOARD_NEWS_COUNT_ERROR,
  payload: err
});

export const fetchDashboardCategoryEquipment = value => ({
  type: FETCH_DASHBOARD_CATEGORY_EQUIPMENT,
  value
});

export const fetchDashboardCategoryEquipmentSuccess = resp => ({
  type: FETCH_DASHBOARD_CATEGORY_EQUIPMENT_SUCCESS,
  payload: resp
});

export const fetchDashboardCategoryEquipmentError = err => ({
  type: FETCH_DASHBOARD_CATEGORY_EQUIPMENT_ERROR,
  payload: err
});
