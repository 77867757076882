import superagent from 'superagent';
import Cookies from 'js-cookie';
import { md5 } from 'js-md5';
import { createLogicWithApi } from '../../../../shared/logicCreators';
import {
  dashboard as apiConfig,
  locations as locationsApiConfig
} from '../../../config/api';
import { 
  FETCH_DASHBOARD_NEWS, 
  fetchDashboardNewsSuccess, 
  fetchDashboardNewsError, 
  CHECK_DASHBOARD_NEWS_COUNT,
  checkDashboardNewsCountSuccess,
  checkDashboardNewsCountError,
  FETCH_DASHBOARD_CATEGORY_EQUIPMENT,
  CANCEL_FETCH_DASHBOARD_CATEGORY_EQUIPMENT,
  fetchDashboardCategoryEquipmentSuccess,
  fetchDashboardCategoryEquipmentError,
} from './Dashboard.actions';

const fetchDashboardNewsLogic = createLogicWithApi({
  type: FETCH_DASHBOARD_NEWS,
  process: (props, dispatch, done) => {
    superagent.get(window.TOOLBOX_ENDPOINT_URL).end((err, resp) => {
      if (err) {
        dispatch(fetchDashboardNewsError(err));
        console.error(err);
        done();
        return;
      }
      dispatch(fetchDashboardNewsSuccess(resp.text));
      done();
    });
  }
});

const checkDashboardNewsCountLogic = createLogicWithApi({
  type: CHECK_DASHBOARD_NEWS_COUNT,
  process: ({ state }, dispatch, done) => {
    if(!state.login.loggedIn) {
      console.warn('Not logged in', state.login);
      done();
      return;
    }
    if (!window.TOOLBOX_COUNT_ENDPOINT_URL) {
      console.warn('TOOLBOX_COUNT_ENDPOINT_URL not defined');
      done();
      return;
    }

    const lastViewed = Cookies.get(
      `BUA_TOOLBOX_PAGEVIEW_${md5(state.login.data.email.toLowerCase())}`, 
      {
        domain: window.location.hostname.split('.').slice(1).join('.'),
      }
    );
    if (!lastViewed) {
      console.warn('No pageview cookie detected');
      done();
      return;
    }

    const lastDate = new Date(lastViewed);
    superagent.get(
      `${window.TOOLBOX_COUNT_ENDPOINT_URL}?date=${lastDate.getFullYear()}-${("0" + (lastDate.getMonth() + 1)).slice(-2)}-${("0" + lastDate.getDate()).slice(-2)}`
    ).end((err, resp) => {
      if (err) {
        dispatch(checkDashboardNewsCountError(err));
        console.error(err);
        done();
        return;
      }
      dispatch(checkDashboardNewsCountSuccess(resp.body.count));
      done();
    });
  }
});

const fetchDashboardCategoryEquipmentLogic = createLogicWithApi({
  type: FETCH_DASHBOARD_CATEGORY_EQUIPMENT,
  cancelType: CANCEL_FETCH_DASHBOARD_CATEGORY_EQUIPMENT,
  latest: true,
  process: ({ Api, action }, dispatch, done) => {
    const category = action.value;
    if (!category) {
      fetchDashboardCategoryEquipmentSuccess([]);
      done();
      return;
    }
    return Api({
      method: 'get',
      path: `${locationsApiConfig.path}${apiConfig.categoryEquipmentPath}`,
      handle: r => r.query({'equipmentCategoryId': category})
    })
    .then(resp => {
      dispatch(fetchDashboardCategoryEquipmentSuccess(resp.data));
      done();
    })
    .catch(err => {
      dispatch(fetchDashboardCategoryEquipmentError(err));
      done();
    })
  }
});

const logics = [
  fetchDashboardNewsLogic,
  checkDashboardNewsCountLogic,
  fetchDashboardCategoryEquipmentLogic,
];

export default logics;
