export const url = typeof window !== 'undefined' && window.API_PATH && window.API_PATH !== '' ? window.API_PATH : '/api/bua/a/v1';

export const dashboard = {
  categoryEquipmentPath: '/all/search-by-equipment',
  identifierPath: '/instances/search',
};

export const login = {
  path: '/token/auth',
  check: '/token/info',
  refresh: '/token/refresh',
  logout: '/users/logout',
  session: '/user/sessions',
  session_refresh: '/refresh',
  forgotPath: '/token/forgot',
  resetPath: '/token/reset',
};

export const reservations = {
  path: '/reservations',
  itemsPath: '/items',
  convertPath: '/convert',
  listPath: '/list'
};

export const loans = {
  path: '/loans',
  itemsPath: '/items',
  availableInstancesPath: '/available-instances',
  closePath: '/close',
  listPath: '/list',
  internalNotesPath: '/internal-note',
};

export const equipment = {
  name: 'equipment',
  path: '/equipment',
  exportPath: '/all/export',
};

export const categories = {
  path: '/categories',
  listPath: '/list',
};

export const organizations = {
  path: '/organizations',
  listPath: '/list',
  filePath: '/files',
};

export const equipmentAttributes = {
  path: '/equipment-attributes',
  listPath: '/equipment-attributes/list'
};

export const variantAttributes = {
  path: '/variant-attributes',
  listPath: '/variant-attributes/list'
};

export const locations = {
  path: '/locations',
  openinghoursPath: '/openinghours',
  removeGeopointPath: '/remove-geoloc',
  entrancePath: '/entrance',
  qrPath: '/qr-code'
};

export const settings = {
  path: '/settings'
};

export const users = {
  path: '/users',
  listPath: '/users/list'
};

export const images = {
  path: '/images'
};

export const variants = {
  path: '/variants'
};

export const instances = {
  path: '/instances',
  generateIdsPath: '/instances/generate/identifiers',
  listPath: '/list',
  searchPath: '/instance-search'
};

export const qrCode = {
  tokenParamName: 'bearer',
};

export const staff = {
  staff: '/staff',
  path: '/admin/users',
  exportPath: '/export',
}

export const list = {
  limit: 20,
  offset: 0
};
