import { replace, push } from '@lagunovsky/redux-react-router';
import Promise from 'pinkie-promise';
import { omit } from 'lodash';
import moment from 'moment';
import { reservations as apiConfig, locations as locationsApiConfig } from '../../../config/api';
import { createLogicWithApi, createLogicWithRouteChecks } from '../../../../shared/logicCreators';
import { showConfirm } from '../../../../shared/components/common/Dialogs/Dialogs.actions';
import { table as tableConfig, defaultStatusFilter } from '../../../config/reservations';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { defaultFilters } from '../../../config/status';
import {
  FETCH_RESERVATIONS,
  CANCEL_FETCH_RESERVATIONS,
  FETCH_RESERVATIONS_SUCCESS,
  FETCH_RESERVATION,
  CANCEL_FETCH_RESERVATION,
  SAVE_RESERVATION,
  CANCEL_SAVE_RESERVATION,
  SAVE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_ITEMS,
  CANCEL_UPDATE_RESERVATION_ITEMS,
  UPDATE_RESERVATION_ITEMS_SUCCESS,
  CONVERT_RESERVATION_TO_LOAN_CHECK,
  CANCEL_CONVERT_RESERVATION_TO_LOAN_CHECK,
  CONVERT_RESERVATION_TO_LOAN,
  CANCEL_CONVERT_RESERVATION_TO_LOAN,
  CONVERT_RESERVATION_TO_LOAN_SUCCESS,
  REMOVE_RESERVATION,
  REMOVE_RESERVATION_SUCCESS,
  CANCEL_REMOVE_RESERVATION,
  removeReservationSuccess,
  removeReservationError,
  fetchReservationsSuccess,
  fetchReservationsError,
  selectReservationTable,
  fetchReservation,
  fetchReservationSuccess,
  fetchReservationError,
  saveReservation,
  saveReservationSuccess,
  saveReservationError,
  updateReservationItemsSuccess,
  updateReservationItemsError,
  convertReservationToLoan,
  convertReservationToLoanSuccess,
  convertReservationToLoanError,
  setReservationCounter,
} from '../../../../shared/components/Reservations/Reservations.actions';

import { SET_LOGIN_INFO } from '../../../../shared/components/Login/Login.actions';

const checkReservationsRouteLogic = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ checkUnselectedIdParam, checkInitSelectWithLocation, getUrl, checkInitLocation}, dispatch, done) => {
    const location = checkInitLocation();

    if (location) {
      const select = checkInitSelectWithLocation('reservations');

      if (select) {
        dispatch(replace(`${getUrl('reservations')}/${location}/${select}${window.location.search}`));
      } else {
        const newIdParam = checkUnselectedIdParam('reservations', location);
        if (newIdParam !== false) {
          dispatch(selectReservationTable(newIdParam));
          dispatch(
            fetchReservation({
              id: newIdParam,
              location: location
            })
          );
        }
      }
    }
    done();
  }
});

const fetchReservationsLogic = createLogicWithApi({
  type: FETCH_RESERVATIONS,
  cancelType: CANCEL_FETCH_RESERVATIONS,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState(),
      location = action.location ? action.location : state.table.currentLocation;

    return Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}`,
      handle: r => {
        const state = getState(),
          sort = action.sort ? action.sort : state.table.items[tableConfig.name].sort,
          desc = action.desc ? action.desc : state.table.items[tableConfig.name].desc,
          search = typeof action.search !== 'undefined' ? action.search : state.table.search,
          limit =  typeof action.limit !== 'undefined' ? action.limit : state.table.items[tableConfig.name].limit,
          offset =  typeof action.offset !== 'undefined' ? action.offset : state.table.items[tableConfig.name].offset;
        let filters = typeof action.filters !== 'undefined' ? action.filters : state.table.filters;
          filters = filters ? filters : {};
          filters = {
            ...defaultFilters,
            status: defaultStatusFilter,
            ...filters
          };

        if (offset) {
          r.query({ 'offset': offset})
        }
        r
          .query({'limit': limit})
          .query({'filters': filters})
          .query({'query': search})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc'});
        return r;
      }
    })
    .then(resp => {
      dispatch(fetchReservationsSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(fetchReservationsError(err));
      done();
    })
  }
});

const checkFetchedReservationsSelectionLogic = createLogicWithRouteChecks({
  type: FETCH_RESERVATIONS_SUCCESS,
  process: ({ checkInitSelectWithLocation, checkInitLocation, getUrl}, dispatch, done) => {
    const location = checkInitLocation();
    const select = checkInitSelectWithLocation('reservations');

    if (select) {
      dispatch(replace(`${getUrl('reservations')}/${location}/${select}${window.location.search}`));
    }
    done();
  }
});

const fetchReservationLogic = createLogicWithApi({
  type: FETCH_RESERVATION,
  cancelType: CANCEL_FETCH_RESERVATION,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState(),
      location = action.location ? action.location : state.table.currentLocation;

    const reservation = Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}`,
    });

    const items = Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}${apiConfig.itemsPath}`,
      params: {
        limit: 0,
        filters: {
          state: 'temporary,published'
        }
      }
    });

    return Promise.all([reservation, items])
      .then(resp => {
        let payload = {
          ...resp[0],
          data: {
            relationships: {},
            ...resp[0].data
          }
        };

        payload.data.relationships.items = {
          ...resp[1]
        };

        dispatch(fetchReservationSuccess(payload));
        done();
      })
      .catch(err => {
        dispatch(fetchReservationError(err));
        done();
      })
  }
});

const saveReservationLogic = createLogicWithApi({
  type: SAVE_RESERVATION,
  cancelType: CANCEL_SAVE_RESERVATION,
  process: ({action, Api, getState}, dispatch, done) => {
    const state = getState(),
      method = action.payload.id ? 'PATCH': 'POST';

    return Api({
      method,
      path: `${locationsApiConfig.path}/${state.table.currentLocation}${apiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`,
      handle: r => {
        return r.send(
          action.payload.id ?
            {
              attributes: action.payload,
              type: 'reservation',
              id: action.payload.id ? action.payload.id : null
            } : {
              attributes: {
                ...action.payload
              }
            }
        );
      }
    })
    .then(resp => {
      dispatch(saveReservationSuccess(resp, action.nextStep, action.payload.id));
      done();
    })
    .catch(err => {
      dispatch(saveReservationError(action.payload.id, err));
      done();
    })
  }
});

const saveReservationSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_RESERVATION_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    if (action.nextStep) {
      dispatch(push(`${getUrl('reservations')}/${state.table.currentLocation}/${action.payload.data.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));
    } else {
      dispatch(push(`${getUrl('reservations')}/${state.table.currentLocation}/${action.payload.data.id}${window.location.search}`));
    }
    done();
  }
});

const updateReservationItemsLogic = createLogicWithApi({
  type: UPDATE_RESERVATION_ITEMS,
  cancelType: CANCEL_UPDATE_RESERVATION_ITEMS,
  process: ({ action, Api, getState}, dispatch, done) => {
    const state = getState(),
      location = action.location ? action.location : state.table.currentLocation,
      items = action.payload.items && action.payload.items.length ? action.payload.items  : [];
    let creations = [],
        promises = [];

    items.forEach(item => {
      if (item.action === 'remove') {
        promises.push(
          Api({
            method: 'DELETE',
            path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.payload.id}${apiConfig.itemsPath}/${item.id}`,
          })
        )
      } else if (item.action === 'update') {
        promises.push(
          Api({
            method: 'PATCH',
            path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.payload.id}${apiConfig.itemsPath}/${item.id}`,
            handle: r => r.send({attributes: omit(item, ['action', 'id'])})
          })
        )
      } else if (item.action === 'create') {
        creations.push(
          omit(item, ['action', 'id'])
        )
      }
    });

    if (creations.length) {
      promises.push(
        Api({
          method: 'POST',
          path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.payload.id}${apiConfig.itemsPath}${apiConfig.listPath}`,
          handle: (r) => {
            return r.send({
              attributes: {
                reservationItems: creations
              }
            });
          }
        })
      )
    }

    return Promise.all(promises)
      .then(resp => {
        if (action.payload.publishReservation) {
          dispatch(
            saveReservation({
              id: action.payload.id,
              state: 'published'
            })
          );
        }
        dispatch(updateReservationItemsSuccess(action.payload.id, resp));
        done();
      })
      .catch(err => {
        dispatch(updateReservationItemsError(err));
        done();
      })
  }
});

const updateReservationItemsSuccessLogic = createLogicWithRouteChecks({
  type: UPDATE_RESERVATION_ITEMS_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    const location = state.table.currentLocation;
    const selected = action.id;

    dispatch(push(`${getUrl('reservations')}/${location}/${selected}${window.location.search}`));
    dispatch(
      fetchReservation({
        id: selected,
        location: location
      })
    );
    done();
  }
});

const convertReservationToLoanCheckLogic = createLogicWithApi({
  type: CONVERT_RESERVATION_TO_LOAN_CHECK,
  cancelType: CANCEL_CONVERT_RESERVATION_TO_LOAN_CHECK,

  process: ({ action: { id }, Api, getState}, dispatch, done) => {
    const state = getState(),
      location = state.table.currentLocation,
      convertAction = convertReservationToLoan({ id });

    return Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${id}${apiConfig.convertPath}`
    })
      .then(() => {
        dispatch(convertAction);
        done();
      })
      .catch(() => {
        dispatch(showConfirm({
          acceptAction: convertAction,
          content: state.locale.reservations.details.force_convert_confirm
        }));
        done();
      })
  }
});

const convertReservationToLoanLogic = createLogicWithApi({
  type: CONVERT_RESERVATION_TO_LOAN,
  cancelType: CANCEL_CONVERT_RESERVATION_TO_LOAN,

  process: ({ action, Api, getState}, dispatch, done) => {
    const state = getState(),
      location = state.table.currentLocation;

    return Api({
      method: 'POST',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}${apiConfig.convertPath}`
    })
      .then(resp => {
        dispatch(convertReservationToLoanSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(convertReservationToLoanError(err));
        done();
      })
  }
});

const convertReservationToLoanSuccessLogic = createLogicWithRouteChecks({
  type: CONVERT_RESERVATION_TO_LOAN_SUCCESS,
  process: ({ getUrl, state, action}, dispatch, done) => {
    const location = state.table.currentLocation;
    dispatch(push(`${getUrl('loans')}/${location}/${action.payload.data.attributes.loan}${state.locale.actions.create.path}/2${window.location.search}`));
    done();
  }
});

const removeReservationLogic = createLogicWithApi({
  type: REMOVE_RESERVATION,
  cancelType: CANCEL_REMOVE_RESERVATION,
  latest: true,
  process: ({ getState, action, Api }, dispatch, done) => {
    const state = getState(),
      location = state.table.currentLocation;

    return Api({
      method: 'DELETE',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}`,
    })
      .then(() => {
        dispatch(removeReservationSuccess({id: action.id}));
        done();
      })
      .catch(err => {
        dispatch(removeReservationError(err));
        done();
      })
  }
});

const checkRemoveReservationLogic = createLogicWithRouteChecks({
  type: REMOVE_RESERVATION_SUCCESS,
  process: ({ getState }, dispatch, done) => {
    var state = getState();
    var location = state.table.currentLocation;

    dispatch(
      push(`${apiConfig.path}/${location}${window.location.search}`)
    );

    done();
  }
});

const checkLoginReservationCountLogic = createLogicWithApi({
  type: [SET_LOGIN_INFO, SAVE_RESERVATION_SUCCESS, CONVERT_RESERVATION_TO_LOAN_SUCCESS, REMOVE_RESERVATION_SUCCESS],
  process: ({ Api, getState }, dispatch, done) => {
    const { login } = getState();
    if (!login?.loggedIn) {
      done();
      return;
    }
    
    const resRoles = Object.keys(login.data.roles).filter(k => k !== 'all' && login.data.roles[k].reservation?.view);
    if (login.data.roles.all?.reservation?.view || (!resRoles.length || resRoles.length > 4)) {
      done();
      return;
    }

    Promise.allSettled(
      resRoles.map(l => Api({
        method: 'GET',
        path: `${locationsApiConfig.path}/${l}${apiConfig.path}`,
        handle: r => {
          let filters = {
            status: 'reserved',
            pickupAt: {
              endDate: moment().add(3, 'days').toISOString(),
            }
          };
          r
            .query({'limit': 1})
            .query({'filters': filters});
          return r;
        }
      }))
    ).then(res => {
      const totalRes = res.reduce((val, r) => val + r.value?.meta.total, 0);
      dispatch(setReservationCounter(totalRes));
      done();
    });
  }
});

const logics = [
  checkReservationsRouteLogic,
  fetchReservationsLogic,
  checkFetchedReservationsSelectionLogic,
  fetchReservationLogic,
  saveReservationLogic,
  saveReservationSuccessLogic,
  updateReservationItemsLogic,
  updateReservationItemsSuccessLogic,
  convertReservationToLoanCheckLogic,
  convertReservationToLoanLogic,
  convertReservationToLoanSuccessLogic,
  removeReservationLogic,
  checkRemoveReservationLogic,
  checkLoginReservationCountLogic,
];

export default logics;