import { combineReducers } from 'redux';

import { CHANGE_LOCATION_ACTION as CHANGE_LOCATION } from '../../config/locations';
import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATIONS_SUCCESS,
  FETCH_RESERVATIONS_ERROR,
  FETCH_RESERVATION,
  FETCH_RESERVATION_SUCCESS,
  FETCH_RESERVATION_ERROR,
  SAVE_RESERVATION,
  SAVE_RESERVATION_SUCCESS,
  SAVE_RESERVATION_ERROR,
  CANCEL_SAVE_RESERVATION,
  REMOVE_RESERVATION,
  REMOVE_RESERVATION_SUCCESS,
  CONVERT_RESERVATION_TO_LOAN_SUCCESS,
  RESET_RESERVATION,
  RESET_RESERVATIONS,
  UPDATE_RESERVATION_ITEMS,
  UPDATE_RESERVATION_ITEMS_SUCCESS,
  UPDATE_RESERVATION_ITEMS_ERROR,
  CANCEL_UPDATE_RESERVATION_ITEMS,
  SET_RESERVATION_COUNTER,
} from '../Reservations/Reservations.actions';

// Meta
const loading = (state = false, action) => {
  switch (action.type) {
    case FETCH_RESERVATIONS:
      return true;

    case FETCH_RESERVATIONS_SUCCESS:
    case FETCH_RESERVATIONS_ERROR:
      return false;

    default:
      return state;
  }
};

const list = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_SUCCESS:
      return action.payload.meta;

    case FETCH_RESERVATIONS_ERROR:
      return false;

    case SAVE_RESERVATION_SUCCESS:
      return {
        ...state,
        total: !action.id ? state.total + 1 : state.total
      };

    case REMOVE_RESERVATION_SUCCESS:
      return {
        ...state,
        total: state.total - 1
      };

    default:
      return state;
  }
};

const unhandled = (state = 0, action) => {
  switch (action.type) {
    case SET_RESERVATION_COUNTER:
      return action.value;
    default:
      return state;
  }
};

const meta = combineReducers({
  loading,
  list,
  unhandled,
});

// ITEMS

const reservation = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS:
      return {
        ...action.payload.data.attributes,
        id: action.payload.data.id,
        type: action.payload.data.type,
        loading: false,
        user: user(state.user, action),
        loan: loan(state.loan, action),
        variants: variants(state.variants, action),
        items: reservationItems(state.items, action)
      };

    case SAVE_RESERVATION_SUCCESS:
      return {
        ...action.payload.data.attributes,
        id: action.payload.data.id,
        type: action.payload.data.type,
        items: state.items && state.items.length ? [...state.items] : [],
        loading: false,
        user: user(state.user, action),
        loan: loan(state.loan, action),
        variants: variants(state.variants, action)
      };

    case FETCH_RESERVATIONS_SUCCESS:
      return {
        ...state.attributes,
        id: state.id,
        type: state.type,
        user: user(state, action),
        loan: loan(state, action),
        variants: variants(state, action),
        items: reservationItems(state, action)
      };

    default:
      return state;
  }
};

const items = (state = [], action) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_SUCCESS:
      return action.payload.data.map(r => reservation(r, action));

    case SAVE_RESERVATION_SUCCESS: {
      const current = state.find(r => String(r.id) === String(action.payload.data.id));

      if (current) {
        return state.map(r => {
          if (String(action.payload.data.id) === String(r.id)) {
            return reservation(r, action);
          }
          return r;
        });

      } else {
        return [...state, reservation({}, action)];
      }
    }
    case REMOVE_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS:
      return state.filter(item => item.id !== parseInt(action.id));

    case FETCH_RESERVATIONS_ERROR:
    case CHANGE_LOCATION:
    case RESET_RESERVATIONS:
      return [];

    default:
      return state;
  }
};

// SELECTED
const selected = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RESERVATION:
      return {
        id: action.id,
        loading: true
      };

    case FETCH_RESERVATION_SUCCESS:
      return reservation(state, action);

    case UPDATE_RESERVATION_ITEMS:
    case SAVE_RESERVATION:
      return {
        ...state,
        loading: true
      };

    case UPDATE_RESERVATION_ITEMS_SUCCESS:
    case UPDATE_RESERVATION_ITEMS_ERROR:
    case CANCEL_UPDATE_RESERVATION_ITEMS:
    case SAVE_RESERVATION_ERROR:
    case CANCEL_SAVE_RESERVATION:
      return {
        ...state,
        loading: false
      };

    case SAVE_RESERVATION_SUCCESS: {
      if (String(action.payload.data.id) === String(state.id)) {
        return reservation(state, action);

      } else {
        return reservation({}, action);
      }
    }
    case REMOVE_RESERVATION:
      return Object.assign(state, { loading: true, isRemoving: true });

    case REMOVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_ERROR:
    case CHANGE_LOCATION:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS:
    case RESET_RESERVATION:
      return {};

    default:
      return state;
  }
};

// RELATIONSHIPS

const user = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_SUCCESS: {
      let current = action.payload.data.find(item => String(item.id) === String(state.id));
      let relationships = current.relationships;

      if (!relationships.user) {
        return state.attributes.user;
      }

      const userState = {
        id: relationships.user.id,
        type: relationships.user.type,
        ...relationships.user.data.attributes
      };

      if (!relationships.user.data.relationships?.organization) {
        return {
          ...userState,
          organization: null,
        };
      }

      return {
        ...userState,
        organization: {
          id: relationships.user.data.relationships.organization.data.id,
          name: relationships.user.data.relationships.organization.data.attributes.name,
        }
      };
    }
    case SAVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS: {
      let relationships = action.payload.data.relationships;

      if (!relationships || !relationships.user) {
        return state;
      }

      const userState = {
        id: relationships.user.id,
        type: relationships.user.type,
        ...relationships.user.data.attributes
      };

      if (!relationships.user.data.relationships?.organization) {
        return {
          ...userState,
          organization: null,
        };
      }

      return {
        ...userState,
        organization: {
          id: relationships.user.data.relationships.organization.data.id,
          name: relationships.user.data.relationships.organization.data.attributes.name,
        }
      };
    }
    default:
      return state;
  }
};

const loan = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_SUCCESS: {
      let current = action.payload.data.find(item => String(item.id) === String(state.id));
      let relationships = current.relationships;

      if (!relationships.loan) {
        return state.attributes.user;
      }

      return {
        id: relationships.loan.id,
        type: relationships.loan.type,
        ...relationships.loan.data.attributes
      };
    }
    case SAVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS: {
      let relationships = action.payload.data.relationships;

      if (!relationships || !relationships.loan) {
        return state;
      }

      return {
        id: relationships.loan.id,
        type: relationships.loan.type,
        ...relationships.loan.data.attributes
      };
    }
    default:
      return state;
  }
};

const variants = (state = [], action) => {
  switch (action.type) {
    case SAVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS: {
      let relationships = action.payload.data.relationships;

      if (
        !relationships ||
        !relationships.variants ||
        !relationships.variants.data.length
      ) {
        return state;
      }

      return relationships.variants.data.map(v => ({
        id: v.id,
        type: v.type,
        ...v.attributes
      }));
    }
    case FETCH_RESERVATIONS_SUCCESS: {
      let current = action.payload.data.find(item => String(item.id) === String(state.id));
      let relationships = current.relationships;

      if (!(relationships.variants && !relationships.variants.data.length)) {
        return state.attributes.variants;
      }

      return relationships.variants.data.map(v => ({
        id: v.id,
        type: v.type,
        ...v.attributes
      }));
    }
    default:
      return state;
  }
};

const reservationItems = (state = [], action) => {
  switch (action.type) {
    case SAVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS: {
      let relationships = action.payload.data.relationships;

      if (
        !relationships ||
        !relationships.items ||
        !relationships.items.data.length
      ) {
        return state;
      }

      return relationships.items.data.map(i => ({
        id: i.id,
        type: i.type,
        ...i.attributes,
        equipment: equipment(i, action),
        brand: brand(i, action),
        images: images(i, action),
        variantAttributeValues: variantAttributeValues(i, action)
      }));
    }
    case FETCH_RESERVATIONS_SUCCESS: {
      let current = action.payload.data.find(item => String(item.id) === String(state.id));
      let relationships = current.relationships;

      if (!relationships.items || !relationships.items.data.length) {
        return state.attributes.items;
      }

      return relationships.items.data.map(i => ({
        id: i.id,
        type: i.type,
        ...i.attributes
      }));
    }
    default:
      return false;
  }
};

const equipment = (state = {}, action) => {
  switch (action.type) {
    case SAVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS: {
      let current = action.payload.data.relationships.items.data.find(item => String(item.id) === String(state.id));

      if (!current.relationships || !current.relationships.equipment) {
        return state;
      }

      return {
        id: current.relationships.equipment.data.id,
        type: current.relationships.equipment.data.type,
        ...current.relationships.equipment.data.attributes
      };
    }
    default:
      return state;
  }
};

const images = (state = [], action) => {
  switch (action.type) {
    case SAVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS: {
      let current = action.payload.data.relationships.items.data.find(item => String(item.id) === String(state.id));

      if (!current.relationships || !current.relationships.images) {
        return state;
      }

      return current.relationships.images.data;
    }
    default:
      return state;
  }
};

const brand = (state = {}, action) => {
  switch (action.type) {
    case SAVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS: {
      let current = action.payload.data.relationships.items.data.find(item => String(item.id) === String(state.id));

      if (!current.relationships || !current.relationships.brand) {
        return state;
      }

      return {
        id: current.relationships.brand.data.id,
        type: current.relationships.brand.data.type,
        ...current.relationships.brand.data.attributes
      };
    }
    default:
      return state;
  }
};

const variantAttributeValues = (state = [], action) => {
  switch (action.type) {
    case SAVE_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_SUCCESS:
    case CONVERT_RESERVATION_TO_LOAN_SUCCESS: {
      let current = action.payload.data.relationships.items.data.find(item => String(item.id) === String(state.id));

      if (
        !current.relationships ||
        !current.relationships.variantAttributeValues
      ) {
        return [];
      }
      return current.relationships.variantAttributeValues.data.map(v => ({
        id: v.id,
        type: v.type,
        ...v.attributes
      }));
    }
    default:
      return state;
  }
};

// MAIN
const reducer = combineReducers({
  meta,
  items,
  selected
});

reducer.reducer = 'reservations';

export default reducer;
