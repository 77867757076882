export const FETCH_STAFF = '@@staff/FETCH_STAFF';
export const FETCH_STAFF_SUCCESS = '@@staff/FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_ERROR = '@@staff/FETCH_STAFF_ERROR';
export const CANCEL_FETCH_STAFF = '@@staff/CANCEL_FETCH_STAFF';
export const RESET_STAFF = '@@staff/RESET_STAFF';

export const TABLE_SELECT_STAFF = '@@staff/TABLE_SELECT_STAFF';

export const FETCH_STAFF_USER = '@@staff/FETCH_STAFF_USER';
export const FETCH_STAFF_USER_SUCCESS = '@@staff/FETCH_STAFF_USER_SUCCESS';
export const FETCH_STAFF_USER_ERROR = '@@staff/FETCH_STAFF_USER_ERROR';
export const CANCEL_FETCH_STAFF_USER = '@@staff/CANCEL_FETCH_STAFF_USER';
export const SAVE_STAFF_USER = '@@staff/SAVE_STAFF_USER';
export const SAVE_STAFF_USER_SUCCESS = '@@staff/SAVE_STAFF_USER_SUCCESS';
export const SAVE_STAFF_USER_ERROR = '@@staff/SAVE_STAFF_USER_ERROR';
export const CANCEL_SAVE_STAFF_USER = '@@staff/CANCEL_SAVE_STAFF_USER';
export const RESET_STAFF_USER = '@@staff/RESET_STAFF_USER';
export const DOWNLOAD_STAFF = '@@staff/DOWNLOAD_STAFF';

export const REMOVE_STAFF_USER = '@@staff/STAFF_USER';
export const REMOVE_STAFF_USER_SUCCESS = '@@staff/REMOVE_STAFF_USER_SUCCESS';
export const REMOVE_STAFF_USER_ERROR = '@@staff/REMOVE_STAFF_USER_ERROR';
export const CANCEL_REMOVE_STAFF_USER = '@@staff/CANCEL_REMOVE_STAFF_USER';

export const selectStaffTable = id => ({
  type: TABLE_SELECT_STAFF,
  id
});

export const fetchStaff = config => ({
  type: FETCH_STAFF,
  ...config
});

export const fetchStaffSuccess = resp => ({
  type: FETCH_STAFF_SUCCESS,
  payload: resp
});

export const fetchStaffError = err => ({
  type: FETCH_STAFF_ERROR,
  payload: err
});

export const resetStaff = () => ({
  type: RESET_STAFF
});

export const saveStaffUser = data => ({
  type: SAVE_STAFF_USER,
  payload: data,
});

export const saveStaffUserSuccess = (resp, id) => ({
  type: SAVE_STAFF_USER_SUCCESS,
  payload: resp,
  id
});

export const saveStaffUserError = (id, err) => ({
  type: SAVE_STAFF_USER_ERROR,
  id,
  payload: err
});

export const fetchStaffUser = config => ({
  type: FETCH_STAFF_USER,
  ...config
});

export const fetchStaffUserSuccess = resp => ({
  type: FETCH_STAFF_USER_SUCCESS,
  payload: resp
});

export const fetchStaffUserError = err => ({
  type: FETCH_STAFF_USER_ERROR,
  payload: err
});

export const resetStaffUser = () => ({
  type: RESET_STAFF_USER
});

export const downloadStaff = () => ({
  type: DOWNLOAD_STAFF
});

export const removeStaffUser = payload => ({
  type: REMOVE_STAFF_USER,
  ...payload
});

export const removeStaffUserSuccess = payload => ({
  type: REMOVE_STAFF_USER_SUCCESS,
  ...payload
});

export const removeStaffUserError = err => ({
  type: REMOVE_STAFF_USER_ERROR,
  payload: err
});
