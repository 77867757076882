import { isObject } from 'lodash';
import superagent from 'superagent';
import Promise from 'pinkie-promise';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

import { timeout } from '../config/api';

let _baseUrl = '',
  _refreshUrl = '',
  _catchCallback,
  _refreshPromise;

const cacheBuster = request => {
  var timestamp = Date.now().toString();
  if (request._query !== undefined && request._query[0]) {
    request._query[0] += '&' + timestamp;
  } else {
    request._query = [timestamp];
  }
  return request;
};

export const defaultTokenCheck = () => {
  const accessToken = Cookies.get(Api.cookieConfig.token.name);

  if (!accessToken) {
    return Promise.resolve(null);
  }

  const parsedToken = jwtDecode(accessToken, { header:true });

  if (!parsedToken.exp) {
    return Promise.resolve(accessToken);
  }

  const expiredTime = parsedToken.exp * 1000;

  if (expiredTime > Number(Date.now()) + 24000) {
    return Promise.resolve(accessToken);
  }

  const refreshToken = Cookies.get(Api.cookieConfig.refresh_token.name);

  if (refreshToken && !_refreshPromise) {
    _refreshPromise = new Promise((resolveRefresh, rejectRefresh) => {
      ApiCall({
        method: 'post',
        path: _refreshUrl,
        withCredentials: true,
        requireLogin: false,
        jsonApi: false,
        handle: r => {
          return r.send({
            refresh_token: refreshToken
          });
        }
      })
        .then(({ token, refresh_token }) => {
          Api.setTokens(token, refresh_token);
          _refreshPromise = null;
          resolveRefresh(token);
        })
        .catch(() => {
          _refreshPromise = null;
          rejectRefresh({ status: 401 });
        });
    });
  } else if (!refreshToken) {
    return Promise.reject({ status: 401 });
  }

  return _refreshPromise;
};

async function Api(options) {
  const accessToken = Api.authConfig?.tokenCheck ? 
    await Api.authConfig.tokenCheck(Api) : 
    await defaultTokenCheck();

  return ApiCall(options, accessToken);
}

Api.authConfig = null;
Api.cookieConfig = null;

function ApiCall(
  {
    method,
    path,
    handle,
    params,
    requireLogin = true,
    jsonApi = true,
    cache = false,
    baseUrl,
    headers,
    withCredentials,
    agent
  },
  accessToken
) {
  return new Promise((resolveApi, rejectApi) => {
    const request = superagent(
      method.toUpperCase(),
      `${baseUrl ? baseUrl : _baseUrl}${path}`
    ).timeout(timeout);
    if (typeof handle === 'function') {
      handle(request);
    }
    if (params) {
      if (typeof params.limit !== 'undefined') {
        request.query({ limit: params.limit });
      }
      if (typeof params.offset !== 'undefined') {
        request.query({ offset: params.offset });
      }
      if (typeof params.filters !== 'undefined') {
        request.query({ filters: params.filters });
      }
      if (typeof params.search !== 'undefined') {
        request.query({ query: params.offset });
      }
      if (typeof params.sort !== 'undefined') {
        request.query({ 'order[field]': params.offset });
      }
      if (typeof params.desc !== 'undefined') {
        request.query({ 'order[dir]': params.desc ? 'desc' : 'asc' });
      }
    }
    if (requireLogin && !accessToken) {
      return rejectApi({ status: 401 });
    }
    if (withCredentials) {
      request.withCredentials();
    }
    if (agent) {
      request.agent(agent);
    }
    if (!cache) {
      request.use(cacheBuster);
    }
    return request
      .use(r => {
        if (jsonApi && r._data && !r._data.data) {
          r._data = { data: { ...r._data } };
        }
        return r;
      })
      .set(
        Object.assign(
          {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          headers
        )
      )
      .end((err, resp) => {
        if (err) {
          return rejectApi(err);
        }
        resolveApi(
          isObject(resp.body) && !(resp.body instanceof Blob) && !Array.isArray(resp.body) && resp.statusCode
            ? { ...resp.body, statusCode: resp.statusCode }
            : resp.body
        );
      });
  }).catch(err => {
    if (typeof _catchCallback === 'function') {
      _catchCallback(err);
    }
    throw err;
  });
}

Api.setTokens = function(access_token, refresh_token) {
  Cookies.set(Api.cookieConfig.token.name, access_token, {
    expires: Api.cookieConfig.token.expires
  });
  if (refresh_token) {
    Cookies.set(Api.cookieConfig.refresh_token.name, refresh_token, {
      expires: Api.cookieConfig.refresh_token.expires
    });
  }
  return this;
};

Api.getTokens = function() {
  return {
    access_token: Cookies.get(Api.cookieConfig.token.name),
    refresh_token:  Cookies.get(Api.cookieConfig.refresh_token.name),
  }
};

Api.setUrls = function({ baseUrl, refreshUrl }) {
  _baseUrl = baseUrl;
  _refreshUrl = refreshUrl;
  return this;
};

Api.setCatchCallback = function(cb) {
  _catchCallback = cb;
  return this;
};

export default Api;
