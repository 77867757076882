export const FETCH_RESERVATIONS = '@@reservations/FETCH_RESERVATIONS';
export const FETCH_RESERVATIONS_SUCCESS = '@@reservations/FETCH_RESERVATIONS_SUCCESS';
export const FETCH_RESERVATIONS_ERROR = '@@reservations/FETCH_RESERVATIONS_ERROR';
export const CANCEL_FETCH_RESERVATIONS = '@@reservations/CANCEL_FETCH_RESERVATIONS';
export const RESET_RESERVATIONS = '@@reservations/RESET_RESERVATIONS';
export const RESET_RESERVATION = '@@reservations/RESET_RESERVATION';

export const FETCH_RESERVATION = '@@reservations/FETCH_RESERVATION';
export const FETCH_RESERVATION_SUCCESS = '@@reservations/FETCH_RESERVATION_SUCCESS';
export const FETCH_RESERVATION_ERROR = '@@reservations/FETCH_RESERVATION_ERROR';
export const CANCEL_FETCH_RESERVATION = '@@reservations/CANCEL_FETCH_RESERVATION';

export const SAVE_RESERVATION = '@@reservations/SAVE_RESERVATION';
export const SAVE_RESERVATION_SUCCESS = '@@reservations/SAVE_RESERVATION_SUCCESS';
export const SAVE_RESERVATION_ERROR = '@@reservations/SAVE_RESERVATION_ERROR';
export const CANCEL_SAVE_RESERVATION = '@@reservations/CANCEL_SAVE_RESERVATION';

export const UPDATE_RESERVATION_ITEMS = '@@reservations/UPDATE_RESERVATION_ITEMS';
export const UPDATE_RESERVATION_ITEMS_SUCCESS = '@@reservations/UPDATE_RESERVATION_ITEMS_SUCCESS';
export const UPDATE_RESERVATION_ITEMS_ERROR = '@@reservations/UPDATE_RESERVATION_ITEMS_ERROR';
export const CANCEL_UPDATE_RESERVATION_ITEMS = '@@reservations/CANCEL_UPDATE_RESERVATION_ITEMS';

export const CONVERT_RESERVATION_TO_LOAN_CHECK = '@@reservations/CONVERT_RESERVATION_TO_LOAN_CHECK';
export const CANCEL_CONVERT_RESERVATION_TO_LOAN_CHECK = '@@reservations/CANCEL_CONVERT_RESERVATION_TO_LOAN_CHECK';

export const CONVERT_RESERVATION_TO_LOAN = '@@reservations/CONVERT_RESERVATION_TO_LOAN';
export const CONVERT_RESERVATION_TO_LOAN_SUCCESS = '@@reservations/CONVERT_RESERVATION_TO_LOAN_SUCCESS';
export const CONVERT_RESERVATION_TO_LOAN_ERROR = '@@reservations/CONVERT_RESERVATION_TO_LOAN_ERROR';
export const CANCEL_CONVERT_RESERVATION_TO_LOAN = '@@reservations/CANCEL_CONVERT_RESERVATION_TO_LOAN';

export const REMOVE_RESERVATION = '@@reservations/REMOVE_RESERVATION';
export const REMOVE_RESERVATION_SUCCESS = '@@reservations/REMOVE_RESERVATION_SUCCESS';
export const REMOVE_RESERVATION_ERROR = '@@reservations/REMOVE_RESERVATION_ERROR';
export const CANCEL_REMOVE_RESERVATION = '@@reservations/CANCEL_REMOVE_RESERVATION';

export const TABLE_SELECT_RESERVATION = '@@reservations/TABLE_SELECT_RESERVATION';

export const SET_RESERVATION_COUNTER = '@@reservations/SET_RESERVATION_COUNTER';

export const selectReservationTable = id => ({
  type: TABLE_SELECT_RESERVATION,
  id
});

export const fetchReservations = config => ({
  type: FETCH_RESERVATIONS,
  ...config
});

export const fetchReservationsSuccess = resp => ({
  type: FETCH_RESERVATIONS_SUCCESS,
  payload: resp
});

export const fetchReservationsError = err => ({
  type: FETCH_RESERVATIONS_ERROR,
  payload: err
});

export const fetchReservation = config => ({
  type: FETCH_RESERVATION,
  ...config
});

export const fetchReservationSuccess = resp => ({
  type: FETCH_RESERVATION_SUCCESS,
  payload: resp
});

export const fetchReservationError = err => ({
  type: FETCH_RESERVATION_ERROR,
  payload: err
});

export const saveReservation = (data, nextStep) => ({
  type: SAVE_RESERVATION,
  payload: data,
  nextStep
});

export const saveReservationSuccess = (resp, nextStep, id) => ({
  type: SAVE_RESERVATION_SUCCESS,
  payload: resp,
  nextStep,
  id
});

export const saveReservationError = (id, err) => ({
  type: SAVE_RESERVATION_ERROR,
  id,
  payload: err
});

export const updateReservationItems = (id, items, publishReservation) => ({
  type: UPDATE_RESERVATION_ITEMS,
  payload: {
    id,
    items,
    publishReservation
  }
});

export const updateReservationItemsSuccess = (id, payload) => ({
  type: UPDATE_RESERVATION_ITEMS_SUCCESS,
  id,
  payload
});

export const updateReservationItemsError = err => ({
  type: UPDATE_RESERVATION_ITEMS_ERROR,
  payload: err
});

export const convertReservationToLoanCheck = ({ id }) => ({
  type: CONVERT_RESERVATION_TO_LOAN_CHECK,
  id
});

export const convertReservationToLoan = ({ id }) => ({
  type: CONVERT_RESERVATION_TO_LOAN,
  id
});

export const convertReservationToLoanSuccess = resp => ({
  type: CONVERT_RESERVATION_TO_LOAN_SUCCESS,
  payload: resp
});

export const convertReservationToLoanError = err => ({
  type: CONVERT_RESERVATION_TO_LOAN_ERROR,
  payload: err
});

export const removeReservation = payload => ({
  type: REMOVE_RESERVATION,
  ...payload
});

export const removeReservationSuccess = payload => ({
  type: REMOVE_RESERVATION_SUCCESS,
  ...payload
});

export const removeReservationError = err => ({
  type: REMOVE_RESERVATION_ERROR,
  payload: err
});

export const resetReservation = () => ({
  type: RESET_RESERVATION
});

export const resetReservations = () => ({
  type: RESET_RESERVATIONS
});

export const setReservationCounter = (value) => ({
  type: SET_RESERVATION_COUNTER,
  value,
});
