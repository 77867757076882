import { replace, push } from '@lagunovsky/redux-react-router';

import { createLogicWithApi, createLogicWithRouteChecks } from '../../../../shared/logicCreators';
import { staff as apiConfig, locations as locationsApiConfig } from '../../../config/api';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { 
  table as tableConfig, 
  LOCATION_ADMIN_ROLE, 
  OPERATOR_ROLE,
  generateAdminRoles, 
  generateOperatorRoles,
  generateNonAdminRoles,
} from '../../../config/staff';

import { getSelected } from './Staff.selectors';
import {
  FETCH_STAFF,
  CANCEL_FETCH_STAFF,
  FETCH_STAFF_SUCCESS,
  SAVE_STAFF_USER,
  CANCEL_SAVE_STAFF_USER,
  SAVE_STAFF_USER_SUCCESS,
  FETCH_STAFF_USER,
  CANCEL_FETCH_STAFF_USER,
  REMOVE_STAFF_USER_SUCCESS,
  REMOVE_STAFF_USER,
  CANCEL_REMOVE_STAFF_USER,
  fetchStaffSuccess,
  fetchStaffError,
  selectStaffTable,
  saveStaffUserSuccess,
  saveStaffUserError,
  fetchStaffUser,
  fetchStaffUserSuccess,
  fetchStaffUserError,
  removeStaffUserSuccess,
  removeStaffUserError,
  DOWNLOAD_STAFF
} from './Staff.actions';

const checkStaffRouterLogic = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ checkUnselectedIdParam, checkInitSelectWithLocation, getUrl, checkInitLocation, getState}, dispatch, done) => {
    const state = getState();
    const locationId = checkInitLocation();

    if (locationId) {
      const select = checkInitSelectWithLocation('staff');

      if (select && String(locationId) === String(state.table.currentLocation)) {
        dispatch(replace(`${getUrl('staff')}/${locationId}/${select}${window.location.search}`));

      } else {
        const newIdParam = checkUnselectedIdParam('staff', locationId);

        if (newIdParam !== false) {
          dispatch(selectStaffTable(newIdParam));
          dispatch(fetchStaffUser({ id: newIdParam }));
        }
      }
    }
    
    done();
  }
});

const fetchStaffLogic = createLogicWithApi({
  type: FETCH_STAFF,
  cancelType: CANCEL_FETCH_STAFF,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState(),
    location = action.location ? action.location : state.table.currentLocation;

    return Api({
      method: 'get',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}`,
      handle: r => {
        const sort = action.sort ? action.sort : state.table.items[tableConfig.name].sort,
          desc = action.desc ? action.desc : state.table.items[tableConfig.name].desc,
          search = typeof action.search !== 'undefined' ? action.search : state.table.search,
          limit =  typeof action.limit !== 'undefined' ? action.limit : state.table.items[tableConfig.name].limit,
          offset =  typeof action.offset !== 'undefined' ? action.offset : state.table.items[tableConfig.name].offset;
        let filters = typeof action.filters !== 'undefined' ? action.filters : state.table.filters;
        filters = filters ? filters : {};
        filters = {
          ...filters
        };

        if (offset) {
          r.query({ 'offset': offset})
        }
        r
          .query({'limit': limit})
          .query({'filters': filters})
          .query({'query': search})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc'});
        return r;
      }
    })
      .then(resp => {
        dispatch(fetchStaffSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchStaffError(err));
        done();
      });
  }
});

const checkFetchedStaffSelectionLogic = createLogicWithRouteChecks({
  type: FETCH_STAFF_SUCCESS,
  process: ({ checkInitSelectWithLocation, checkInitLocation, getUrl}, dispatch, done) => {
    const location = checkInitLocation();
    const select = checkInitSelectWithLocation('staff');

    if (select) {
      dispatch(replace(`${getUrl('staff')}/${location}/${select}${window.location.search}`));
    }

    done();
  }
});

const saveStaffUserLogic = createLogicWithApi({
  type: SAVE_STAFF_USER,
  cancelType: CANCEL_SAVE_STAFF_USER,
  process: ({action, Api, getState}, dispatch, done) => {
    const method = action.payload.id ? 'PATCH': 'POST';
    const state = getState();
    const location = action.location ? action.location : state.table.currentLocation;
    const { type, ...user} = action.payload;
    const roles = type === LOCATION_ADMIN_ROLE 
      ? generateAdminRoles(location) 
      : type === OPERATOR_ROLE
      ? generateOperatorRoles(location)
      : generateNonAdminRoles(location);

    return Api({
      method,
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}${user.id ? `/${user.id}` : ''}`,
      handle: r => {
        return r.send(
          user.id ?
            {
              attributes: {
                username: user.email,
                ...user,
                roles
              },
              type: 'admin_user',
              id: user.id ? user.id : null,
            } : {
              attributes: {
                username: user.email,
                password: `${(Math.random() + 1).toString(36)}${(Math.random() + 1).toString(36)}`,
                ...user,
                roles,
                state: "published"
              }
            }
        );
      }
    })
    .then(resp => {
      dispatch(saveStaffUserSuccess(resp, user.id));
      done();
    })
    .catch(err => {
      dispatch(saveStaffUserError(user.id, err));
      done();
    })
  }
});

const saveStaffUserSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_STAFF_USER_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    dispatch(push(`${getUrl('staff')}/${state.table.currentLocation}/${action.payload.data.id}${window.location.search}`));
    done();
  }
});

const fetchStaffUserLogic = createLogicWithApi({
  type: FETCH_STAFF_USER,
  cancelType: CANCEL_FETCH_STAFF_USER,
  latest: true,
  process: ({ action, getState, Api }, dispatch, done) => {
    const state = getState();
    const location = action.location ? action.location : state.table.currentLocation;

    return Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}`,
    })
    .then(resp => {
      dispatch(fetchStaffUserSuccess(resp.data));
      done();
    })
    .catch(err => {
      dispatch(fetchStaffUserError(err));
      done();
    })
  }
});

const removeStaffUserLogic = createLogicWithApi({
  type: REMOVE_STAFF_USER,
  cancelType: CANCEL_REMOVE_STAFF_USER,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const location = action.location ? action.location : state.table.currentLocation;
    const user = getSelected(state);

    return Api({
      method: 'PATCH',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}`,
      handle: r => {
        return r.send(
          {
            attributes: {
              roles: user.roles.filter(role => !role.includes(location))
            },
            type: 'admin_user',
            id: action.id,
          }
        );
      }
    })
      .then(() => {
        dispatch(removeStaffUserSuccess({id: action.id}));
        done();
      })
      .catch(err => {
        dispatch(removeStaffUserError(err));
        done();
      })
  }
});

const checkRemoveStaffUserLogic = createLogicWithRouteChecks({
  type: REMOVE_STAFF_USER_SUCCESS,
  process: ({ getState }, dispatch, done) => {
    const state = getState();
    const location = state.table.currentLocation;

    dispatch(
      push(`${apiConfig.staff}/${location}${window.location.search}`)
    );

    done();
  }
});

const downloadStaffLogic = createLogicWithApi({
  type: DOWNLOAD_STAFF,
  latest: true,
  process: ({ Api }, dispatch, done) => {
    return Api({
      method: 'POST',
      path: `${apiConfig.path}${apiConfig.exportPath}`,
      handle: r => r.responseType('blob'),
    })
      .then(file => {
        const fileUrl = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.download = `admin-brukere.xlsx`;
        document.body.appendChild(fileLink);
        fileLink.click();
        URL.revokeObjectURL(fileUrl);
        fileLink.remove();
        done();
      })
      .catch(err => {
        console.error(err);
        done();
      })
  }
});

const logics = [
  fetchStaffLogic,
  checkFetchedStaffSelectionLogic,
  checkStaffRouterLogic,
  saveStaffUserLogic,
  saveStaffUserSuccessLogic,
  fetchStaffUserLogic,
  removeStaffUserLogic,
  checkRemoveStaffUserLogic,
  downloadStaffLogic,
];

export default logics;