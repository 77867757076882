import React from 'react';
import PropTypes from 'prop-types';

import CloseButton from './CloseButton/CloseButton.styled';
import StyledModal from './Modal.styled';

const Modal = ({ 
  children, 
  clickClose, 
  full, 
  middle, 
  multi, 
  onClose, 
  levels, 
  hideCloseButton, 
  closeCallback 
}) => (
  <StyledModal open $full={full} $middle={middle} $multi={multi}>
    <div>
      <div>
        <div>
          { children }
        </div>
      </div>
    </div>

    {!hideCloseButton && (
      <CloseButton $dark={ full } href="#" onClick={e => {
        e.preventDefault();

        typeof onClose === 'function'
          ? onClose() 
          : clickClose(levels);

        closeCallback?.();
      }} />
    )}
  </StyledModal>
);

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  clickClose: PropTypes.func,
  full: PropTypes.bool,
  middle: PropTypes.bool,
  multi: PropTypes.bool,
  onClose: PropTypes.func,
  levels: PropTypes.number,
  hideCloseButton: PropTypes.bool,
  closeCallback: PropTypes.func
};

export default Modal;
