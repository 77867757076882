import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../hoc/withSvg';

const Logo = ({ theme, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 30" {...props}>
    <path
      fill={fill ? fill : theme.colorWhite}
      fillRule="evenodd"
      d="M73.997 29.987l.003.01H46.354l.003-.01 1.88-5.175h18.029L60.176 8.05l-5.024 13.832h-5.85L57.247.004h5.857l10.892 29.983zM24.559 19.27V.015h5.851v19.298c0 3.533 1.733 5.476 4.81 5.476 3.077 0 4.854-1.943 4.854-5.476V.015h5.85v19.254c0 6.845-4.16 10.73-10.704 10.73-6.5 0-10.661-3.885-10.661-10.73zM0 30V0h12.16c5.532 0 8.16 3.553 8.16 7.646 0 3.733-2.084 6.207-4.896 6.882 3.135.495 5.403 3.643 5.403 7.331 0 4.588-2.628 8.141-8.252 8.141H0zM14.365 8.68c0-1.978-1.107-3.512-3.32-3.512H5.928v6.93h5.117c2.213 0 3.32-1.348 3.32-3.417zm.507 12.37c0-2.07-1.245-3.689-3.596-3.689H5.928v7.47h5.348c2.305 0 3.596-1.443 3.596-3.782z"
    />
  </svg>
);

Logo.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(Logo);
