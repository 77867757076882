import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const fullBottom = css`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 32px;
  background-color: ${props => props.theme.colorGrayLighter};
  border-top: ${props => props.theme.colorGrayLight} solid 1px;
  text-align: right;
`;

const StyledModal = styled.dialog`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 100;
  background-color: ${props => transparentize(.12, props.theme.colorGrayDarker)};
  border: 0;

  & > div {
    width: 100%;
    height: 100%;
    display: table;

    & > div {
      display: table-cell;
      vertical-align: middle;
      padding: ${props => props.$full ? '0' : '32px'};

      & > div {
        position: relative;
        width: ${props => props.$full ? '100%' : 'auto'};
        max-width: ${props => props.$full ? '100%' : props.$middle ? '896px' : '464px'};
        min-height: ${props => props.$full ? '100%' : '0'};
        ${props => props.$multi && css`
          min-width: 464px;
          max-width: 896px;
          width: min-content;
        `};
        padding: ${props => props.$full ? '60px 32px 96px' : '32px'};
        margin: auto;
        background-color: ${props => props.theme.colorWhite};
      }
    }
  }

  .modal__header {
    margin-bottom: 24px;

    small {
      display: block;
      font-size: 13px;
      line-height: 1.38;
      text-align: left;
      color: ${props => props.theme.colorGrayDark};
    }
  }

  .buttons {
    text-align: right;
    margin-top: 48px;
  }

  .bottom {
    ${props => props.$full ? fullBottom : null}
  }
`;

export default StyledModal;
